import adidas from '../img/clients/adidas.png';
import athens from '../img/clients/athens.png';
import avion from '../img/clients/avion.png';
import avirex from '../img/clients/avirex.png';
import champion from '../img/clients/champion.png';
import cingular from '../img/clients/cingular.png';
import cocacola from '../img/clients/coca-cola.png';
import corrientelatina from '../img/clients/corrientelatina.png';
import dcac from '../img/clients/dcac.png';
import durango from '../img/clients/durango.png';
import espn from '../img/clients/espn.png';
import fox from '../img/clients/fox.png';
import goya from '../img/clients/goya.png';
import heineken from '../img/clients/heineken.png';
import honda from '../img/clients/honda.png';
import jack from '../img/clients/jack.png';
import jameson from '../img/clients/jameson.png';
import jose from '../img/clients/jose.png';
import latino106 from '../img/clients/latino106.png';
import microsoft from '../img/clients/microsoft.png';
import moet from '../img/clients/moet.png';
import op from '../img/clients/op.png';
import puma from '../img/clients/puma.png';
import rocawear from '../img/clients/rocawear.png';
import samsung from '../img/clients/samsung.png';
import sony from '../img/clients/sony.png';
import sprint from '../img/clients/sprint.png';
import target from '../img/clients/target.png';
import tmobile from '../img/clients/tmobile.png';
import wutang from '../img/clients/wutang-clan.png';


export default function ClientList(){
    return(
        <>
        <div className=' bg-[#f2f2f2] px-6 py-12'>
            <div className="max-w-7xl mx-auto text-center">
                <div className="grid grid-cols-3 md:grid-cols-6 gap-3 mx-auto">
                <img src={adidas}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={athens}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={avion}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={avirex}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={champion}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={cingular}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={cocacola}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={corrientelatina}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={dcac}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={durango}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={espn}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={fox}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={goya}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={heineken}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={honda}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={jack}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={jameson}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={jose}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={latino106}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={microsoft}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={moet}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={op}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={puma}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={rocawear}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={samsung}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={sony}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={sprint}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={target}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={tmobile}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                <img src={wutang}  alt="Client" className='h-28 md:h-32 w-auto mx-auto' />
                </div>
            </div>
        </div>
        </>
    )
}