import Logo from '../img/ray-logo.png';
export default function Header(){
    return (
        <>
        <div className="py-20 max-w-4xl mx-auto px-8 lg:px-0 font-typewriter">
            <img src={Logo} alt="Ray Roq Logo" className='w-48 mx-auto' />
            <h1 className="md:text-3xl text-3xl text-center mb-6 mt-6 font-typewriter">Ray Acosta AKA rayroQ</h1>
            <p className='text-2xl text-center font-typewriter mt-8 md:mt-16'><strong>Ray Acosta</strong> is an award winning visionary balancing his creativity between the world of Wu-Tang, visual and digital art.</p>
        </div>
        </>
    )
}